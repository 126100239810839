import { Component, OnInit } from '@angular/core';
import { HomeService } from './home.service';
import { ParamMap, Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  [x: string]: any;

  pageIndex: any = {
    common: 1,
    product: 1,
    tutorial: 1,
    youtube: 1,
    blog: 1,
    podcast: 1,
    commonLast: 0,
    productLast: 0,
    tutorialLast: 0,
    youtubeLast: 0,
    blogLast: 0,
    podcastLast: 0,
  };
  selectedSubCategory: any = {
    common: 'all',
    product: 'all',
    tutorial: 'all',
    youtube: 'all',
    blog: 'all',
    podcast: 'all',
  };
  bannerFeeds: any = [];
  searchResults:any =[];
  conferenceFeeds:any = [];
  searchText:any = '';
  newsFeeds: any = [];
  productFeeds: any = [];
  twitterFeeds: any = [];
  tutorialsFeeds: any = [];
  youtubeFeeds: any = [];
  blogFeeds: any = [];
  podcastFeeds: any = [];
  categories: any = [];
  subCategories: any = [];

  constructor(public home: HomeService , public route: ActivatedRoute) { }

  ngOnInit() {
    this.searchText = this.route.snapshot.queryParamMap.get('s');
    if(this.searchText){
      this.getSearchResults(this.searchText);
    }else{
      this.getFeeds();
      this.getBannerFeed();
      this.getProductFeed();
      this.getTwitterFeed();
      this.getTutorialsFeed();
      this.getYoutubeFeed();
      this.getBlogsFeed();
      this.getPodcastFeed();
      this.getCategories();
      this.getSubCategories();
      this.getConferenceFeed();
    }
  }
  getFeeds() {
    // need 5 record per request
    this.newsFeeds = [];
    this.home.getFeeds('Common', 5, 1, 'all').subscribe((data: { data: {} }) => {
      if (data.data) {
        this.newsFeeds = data.data;
      }
    });
  }

  getBannerFeed() {
    // need 5 record per request
    this.newsFeeds = [];
    this.home.getBannerFeed().subscribe((data: { data: {} }) => {
      if (data.data) {
        this.bannerFeeds[0] = data.data;
        this.bannerFeeds[1] = data.data;
      }
    });
  }

  getSearchResults(searchValue){
    this.home.getResults(searchValue).subscribe((data: { data : {}}) => {
      if(data.data){
        this.searchResults = data.data;
      }else{
        this.searchResults = [];
      }
    });
  }
  getProductFeed() {
    // need 5 record per request
    this.productFeeds = [];
    this.home.getFeeds('Product news', 5, 1, 'all').subscribe((data: { data: {} }) => {
      if (data.data) {
        this.productFeeds = data.data;
      }
    });
  }

  getTwitterFeed() {
    // need 3 record per request
    this.twitterFeeds = [];
    this.home.getFeeds('Twitter Live Feed', 6, 1, 'all').subscribe((data: { data: {} }) => {
      if (data.data) {
        this.twitterFeeds = data.data;
      }
    });
  }

  getTutorialsFeed() {
    // need 5 record per request
    this.tutorialsFeeds = [];
    this.home.getFeeds('Testing tutorial', 5, 1, 'all').subscribe((data: { data: {} }) => {
      if (data.data) {
        this.tutorialsFeeds = data.data;
      }
    });
  }

  getYoutubeFeed() {
    // need 4 record per request
    this.youtubeFeeds = [];
    this.home.getFeeds('youtube', 4, 1,'all').subscribe((data: { data: {} }) => {
      if (data.data) {
        this.youtubeFeeds = data.data;
      }
    });
  }

  getBlogsFeed() {
    // need 4 record per request
    this.blogFeeds = [];
    this.home.getFeeds('blog', 4, 1,'all').subscribe((data: { data: {} }) => {
      if (data.data) {
        this.blogFeeds = data.data;
      }
    });
  }

  getPodcastFeed() {
    // need 3 record per request
    this.podcastFeeds = [];
    this.home.getFeeds('Testing Podcast', 3, 1,'all').subscribe((data: { data: {} }) => {
      if (data.data) {
        this.podcastFeeds = data.data;
      }
    });
  }

  getConferenceFeed() {
    // need 3 record per request
    this.conferenceFeeds = [];
    this.home.getFeeds('Testing Conference', 4, 1,'all').subscribe((data: { data: {} }) => {
      if (data.data) {
        this.conferenceFeeds = data.data;
      }
    });
  }

  getCategories() {
    // need 3 record per request
    this.categories = [];
    this.home.getCategories().subscribe((data: { data: {} }) => {
      if (data.data) {
        this.categories = data.data;
      }
    });
  }

  //get Sub-categories of categories

  getSubCategories() {
    // need 3 record per request
    this.categories = [];
    this.home.getSubCategories().subscribe((data: { data: {} }) => {
      if (data.data) {
        this.subCategories = data.data;
        console.log("subCategories::",this.subCategories.filter(t=>t.Category == 'Blog'));
      }
    });
  }

  //next to pagination

  next(category:any, subCategory: string = 'all' , $event:any) {
    switch (category) {
      case "Common":
        if (this.pageIndex.commonLast == 0) {
          this.pageIndex.common++;
          this.home.getFeeds(category, 5, this.pageIndex.common, subCategory).subscribe((data: { data: {} }) => {
            if (data.data) {
              this.newsFeeds = data.data;
              if(this.newsFeeds.length < 5){
                this.pageIndex.commonLast = 1;
              }
            }
            else {
              this.pageIndex.commonLast = 1;
            }
          });
        }
        break;

      case "Product news":
        if (this.pageIndex.productLast == 0) {
          this.pageIndex.product++;
          this.home.getFeeds(category, 5, this.pageIndex.product, subCategory).subscribe((data: { data: {} }) => {
            if (data.data) {
              this.productFeeds = data.data;
              if(this.productFeeds.length < 5){
                this.pageIndex.productLast = 1;
              }
            }
            else {
              this.pageIndex.productLast = 1;
            }
          });
        }
        break;

      case "Testing tutorial":
        if (this.pageIndex.tutorialLast == 0) {
          this.pageIndex.tutorial++;
          this.home.getFeeds(category, 5, this.pageIndex.tutorial, subCategory).subscribe((data: { data: {} }) => {
            if (data.data) {
              this.tutorialsFeeds = data.data;
              if(this.tutorialsFeeds.length < 5){
                this.pageIndex.tutorialLast = 1;
              }
            }
            else {
              this.pageIndex.tutorialLast = 1;
            }
          });
        }
        break;

      case "youtube":
        if (this.pageIndex.youtubeLast == 0) {
          this.pageIndex.youtube++;
          this.youtubeFeeds = [];
          this.home.getFeeds(category, 4, this.pageIndex.youtube, subCategory).subscribe((data: { data: {} }) => {
            if (data.data) {
              this.youtubeFeeds = data.data;
              if(this.youtubeFeeds.length < 5){
                this.pageIndex.youtubeLast = 1;
              }
            }
            else {
              this.pageIndex.youtubeLast = 1;
            }
          });
        }
        break;

      case "blog":
        this.pageIndex.blog++;
        this.home.getFeeds(category, 4, this.pageIndex.blog, subCategory).subscribe((data: { data: {} }) => {
          if (data.data) {
            for (var i=0; i< 4; i++){
              this.blogFeeds.push(data.data[i]);
          }
            if(this.blogFeeds.length < 5){
              this.pageIndex.blogLast = 1;
            }
          } else {
            this.pageIndex.blogLast = 1;
          }
        });
        break;

      case "Testing Podcast":
        if (this.pageIndex.podcastLast == 0) {
          this.pageIndex.podcast++;
          this.home.getFeeds(category, 3, this.pageIndex.podcast, subCategory).subscribe((data: { data: {} }) => {
            if (data.data) {
              this.podcastFeeds = data.data;
              if(this.podcastFeeds.length < 5){
                this.pageIndex.podcastLast = 1;
              }
            }
            else {
              this.pageIndex.podcastLast = 1;
            }
          });
        }
        break;

      default:
        confirm("Sorry, that color is not in the system yet!");
    }
    $event.stopPropagation();
  }

  //previous button pagination
  prev(category, subCategory: string = 'all', $event) {
    switch (category) {
      case "Common":
        if (this.pageIndex.common !== 1) {
          this.pageIndex.common--;
          this.pageIndex.commonLast = 0;
          this.home.getFeeds(category, 5, this.pageIndex.common, subCategory).subscribe((data: { data: {} }) => {
            if (data.data) {
              this.newsFeeds = data.data;
            }
          });
        }
        break;

      case "Product news":
        if (this.pageIndex.product !== 1) {
          this.pageIndex.product--;
          this.pageIndex.productLast = 0;
          this.home.getFeeds(category, 5, this.pageIndex.product, subCategory).subscribe((data: { data: {} }) => {
            if (data.data) {
              this.productFeeds = data.data;
            }
          });
        }
        break;

      case "Testing tutorial":
        if (this.pageIndex.tutorial !== 1) {
          this.pageIndex.tutorial--;
          this.pageIndex.tutorialLast = 0;
          this.home.getFeeds(category, 5, this.pageIndex.tutorial, subCategory).subscribe((data: { data: {} }) => {
            if (data.data) {
              this.tutorialsFeeds = data.data;
            }
          });
        }
        break;

      case "youtube":
        if (this.pageIndex.youtube !== 1) {
          this.pageIndex.youtube--;
          this.pageIndex.youtubeLast = 0;
          this.youtubeFeeds = [];
          this.home.getFeeds(category, 4, this.pageIndex.youtube, subCategory).subscribe((data: { data: {} }) => {
            if (data.data) {
              this.youtubeFeeds = data.data;
            }
          });
        }
        break;

      case "Testing Podcast":
        if (this.pageIndex.podcast !== 1) {
          this.pageIndex.podcast--;
          this.pageIndex.podcastLast = 0;
          this.home.getFeeds(category, 3, this.pageIndex.podcast, subCategory).subscribe((data: { data: {} }) => {
            if (data.data) {
              this.podcastFeeds = data.data;
            }
          });
        }
        break;

      default:
        confirm("Sorry, that color is not in the system yet!");
    }
    $event.stopPropagation();
  }

  //subCategory button pagination
  subcategoryfeed(category, subCategory: string = 'all', $event) {
    switch (category) {
      case "Common":
          this.pageIndex.common = 1;
          this.selectedSubCategory.common = subCategory;
          this.home.getFeeds(category, 5, this.pageIndex.common, subCategory).subscribe((data: { data: {} }) => {
            if (data.data) {
              this.newsFeeds = data.data;
            }
          });
        break;

      case "Product news":
          this.pageIndex.product = 1;
          this.selectedSubCategory.product = subCategory;
          this.home.getFeeds(category, 5, this.pageIndex.product, subCategory).subscribe((data: { data: {} }) => {
            if (data.data) {
              this.productFeeds = data.data;
            }
          });
        break;

      case "Testing tutorial":
          this.pageIndex.tutorial = 1;
          this.selectedSubCategory.tutorial = subCategory;
          this.home.getFeeds(category, 5, this.pageIndex.tutorial, subCategory).subscribe((data: { data: {} }) => {
            if (data.data) {
              this.tutorialsFeeds = data.data;
            }
          });
        break;

      case "Testing Podcast":
          this.pageIndex.podcast = 1;
          this.selectedSubCategory.podcast = subCategory;
          this.home.getFeeds(category, 3, this.pageIndex.podcast, subCategory).subscribe((data: { data: {} }) => {
            if (data.data) {
              this.podcastFeeds = data.data;
            }
          });
        break;

      default:
        confirm("Sorry, that color is not in the system yet!");
    }
    $event.stopPropagation();
  }

}
