import { Component } from '@angular/core';
import { Router ,NavigationEnd  } from '@angular/router';

declare var $: any; 
declare var google: any;  
declare var navigator:any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Jannah';
  currentUrl:any;
  headerDisplay=false;
  footerDisplay=false;
  
  constructor(private router:Router) { }

  ngOnInit() {
    // do something with the parameters
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd ) {
        this.currentUrl = event.url;  // event.url has current url
      }
    });
  }  
}

