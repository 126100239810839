import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

const endpoint = 'http://feed.pos-erp.in/api/Feed/';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor(private http: HttpClient) {}
  private extractData(res: Response) {
    let body = res;
    return body || { };
  }

  getCategories(): Observable<any> {
    return this.http.get(endpoint+'FeedMenuList').pipe(
      map(this.extractData));
  }

  getResults(search): Observable<any> {
    return this.http.get(endpoint+'SearchData?pinCri='+search).pipe(
      map(this.extractData));
  }
}
