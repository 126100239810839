import { Component, OnInit } from '@angular/core';
import {formatDate} from '@angular/common';
import { HeaderService } from './header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {

  categories:any = [];

  constructor(public header:HeaderService) { }

  now: any = '';
  noDataFound: any = 1;
  searchResults:any =[];
  loader:any = 0 ;
  searchText: any = '';


  ngOnInit() {
    this.now = formatDate(new Date(), 'EEEE, dd MMMM yyyy', 'en');
    this.getCategories();
  }

  onSearchChange(searchValue : string ) {
    this.searchText = searchValue;
    this.searchResults = [];
    if(searchValue.length > 2){
      this.loader = 1;
      this.header.getResults(searchValue).subscribe((data: { data : {}}) => {
        if(data.data){
          this.noDataFound = 1;
          this.searchResults = data.data;
          this.loader = 0;
        }else{
          this.searchResults = [];
          this.noDataFound = 0;
          this.loader = 0;
        }
      });
    }else{
      this.searchResults = [];
      this.noDataFound = 1;
    }
  }

  getCategories() {
    // need 3 record per request
    this.categories = [];
    this.header.getCategories().subscribe((data: { data : {}}) => {
      if(data.data){
        this.categories = data.data;
      }
    });
  }

}
